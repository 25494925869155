import httpAuthClient from './httpAuthClient';

const url = `usuario`;

export default {
  getById(id) {
    return httpAuthClient.get(`/${url}/${id}`);
  },
  get() {
    return httpAuthClient.get(`/${url}`);
  },
  search({ usuarioTipoId, nome }) {
    let urlSearch = `/${url}/search?usuarioTipoId=${usuarioTipoId}`;

    if (nome) {
      urlSearch = `${urlSearch}&nome=${nome}`;
    }
    return httpAuthClient.get(urlSearch);
  },
  put(usuario) {
    return httpAuthClient.put(`/${url}`, usuario);
  },
  post(usuario) {
    return httpAuthClient.post(`/auth/register`, usuario);
  },
  delete(id) {
    return httpAuthClient.delete(`/${url}/${id}`);
  },
  resetarSenha(id) {
    return httpAuthClient.post(`/${url}/ResetarSenha/${id}`);
  },
  alterarSenha(form) {
    return httpAuthClient.put(`/${url}/AlterarSenha`, form);
  },
};
