<template>
  <section class="section" id="gerenciar-ingressos">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3 has-text-primary">
            Gerenciamento de Usuários
          </h3>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <router-link
            to="/admin/cadastrar-usuario"
            class="button is-primary is-fullwidth"
          >
            <b-icon icon="plus" class="mr-1" />
            Cadastrar Usuario
          </router-link>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <b-field label="Tipo de Usuário">
            <b-select
              v-model="filtro.usuarioTipoId"
              name="tipo-usuario"
              placeholder="Selecione"
              expanded
              required
            >
              <option
                v-for="tipo in tiposUsuario"
                :key="tipo.id"
                :value="tipo.id"
              >
                {{ tipo.tipo }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Nome">
            <b-input
              type="text"
              v-model="filtro.nome"
              placeholder="Buscar por nome"
            />
          </b-field>
        </div>
        <div class="column" style="display: flex;align-items: flex-end;">
          <b-button type="is-secondary" expanded @click="getUsuarios">
            Buscar
          </b-button>
        </div>
      </div>
      <section
        v-if="usuarios.length === 0 && !isLoading"
        class="section has-background-light has-text-centered"
        style="border-radius: 10px"
      >
        Nenhum resultado encontrado para a sua busca.
      </section>
      <b-table
        v-else
        :data="usuarios"
        :loading="isLoading"
        hoverable
        mobile-cards
      >
        <b-table-column field="nome" label="Nome" v-slot="props">
          {{ props.row.nome }}
        </b-table-column>

        <b-table-column
          field="tipo"
          label="Tipo"
          v-slot="props"
          centered
          :width="200"
        >
          {{ props.row.usuarioTipo.tipo }}
        </b-table-column>

        <b-table-column field="acoes" label="Ações" v-slot="props" centered>
          <b-button
            type="is-secondary"
            size="is-small"
            class="mr-3"
            icon-left="pencil"
            @click="editUsuario(props.row)"
          />
          <b-button
            type="is-danger"
            size="is-small"
            icon-left="delete"
            @click="deleteUsuario(props.row)"
          />
        </b-table-column>
      </b-table>
    </div>
    <ModalConfirmar
      :ativo="isModalConfirmDeleteActive"
      @cancel="cancelDeleteUsuario"
      @confirm="confirmDeleteUsuario"
    />
  </section>
</template>

<script>
import ModalConfirmar from '@/components/ModalConfirmar.vue';
import usuarioTipoService from '@/services/usuarioTipoService';
import usuarioService from '@/services/usuarioService';

export default {
  name: 'GerenciarUsuarios',
  components: { ModalConfirmar },
  data() {
    return {
      isLoading: false,
      isModalConfirmDeleteActive: false,
      filtro: {},
      usuarios: [],
      tiposUsuario: [],
      trashUsuario: null,
    };
  },
  created() {
    this.isLoading = true;
    this.getTipoUsuario().then(() => {
      this.getUsuarios();
    });
  },
  methods: {
    async getTipoUsuario() {
      await usuarioTipoService.get().then(({ data }) => {
        this.tiposUsuario = data;
        const [usuarioTipo] = data;
        if (usuarioTipo) {
          this.filtro.usuarioTipoId = usuarioTipo.id;
        }
      });
    },

    async getUsuarios() {
      this.isLoading = true;
      await usuarioService
        .search(this.filtro)
        .then(({ data }) => {
          this.usuarios = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    editUsuario({ id }) {
      this.$router.push(`/admin/editar-usuario/${id}`);
    },
    deleteUsuario(usuario) {
      this.trashUsuario = usuario;
      this.isModalConfirmDeleteActive = true;
    },
    cancelDeleteUsuario() {
      this.trashUsuario = null;
      this.isModalConfirmDeleteActive = false;
    },
    confirmDeleteUsuario() {
      usuarioService
        .delete(this.trashUsuario.id)
        .then(() => {
          this.$buefy.snackbar.open(`Usuário excluído com sucesso!`);
          this.getUsuarios();
          this.trashUsuario = null;
          this.isModalConfirmDeleteActive = false;
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Erro ao excluir tarifa',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        });
    },
  },
};
</script>
