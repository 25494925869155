<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>{{ title }}</b>
        </p>
      </header>
      <section class="modal-card-body">
        <p>
          {{ message }}
        </p>
        <p v-if="!hideDefaultMessage">Esta ação não poderá ser desfeita.</p>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="cancel">
          Cancelar
        </button>
        <button
          class="button is-primary"
          style="float: right"
          name="confirmar"
          @click="confirm"
        >
          Confirmar
        </button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalConfirmar',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Confirmar exclusão',
    },
    message: {
      type: String,
      default: 'Você tem certeza que deseja excluir esse registro?',
    },
    hideDefaultMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalAtivo: false,
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
