import httpClient from './httpClient';

const url = `usuarioTipo`;

export default {
  getById(id) {
    return httpClient.get(`/${url}/${id}`);
  },
  get() {
    return httpClient.get(url);
  },
};
